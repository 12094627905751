import React from "react";
import Header from "../../../components/Header";

const HeroSection: React.FC = () => {
  return (
    <div className="hero_section_container">
      <Header />
      <div className="content">
        <h1>OCA Shopping Mall<br /> <span>Well-Earned Points at Work for You</span></h1>
        {/* <p>
        Explore the vast array of incredible deals waiting for you,<br/>
        all thanks to your hard-earned points.<br/>
        Explore, shop, and save like never before
        </p> */}
        <p>
        Welcome to your one-stop destination for redeeming performance points! Explore over 500 items across exciting categories like travel essentials, kids' toys, food, and unique experiences—rewarding your hard work has never been this fun!
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
